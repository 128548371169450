// ============================================================================ //
//  Placeholders
// ============================================================================ //
%_white-banner-title {
    font-size: $font-size-hero;
    color: $white;
    font-weight: normal;
    margin-top: 0;
    text-align: center;
}

// ============================================================================ //
//  Styles
// ============================================================================ //
.page-header-banner {
    background: no-repeat center center;
    background-size: cover;

    > .container {
        position: relative;
        z-index: $z-index--page-title;
    }

    .page-header-banner-title {
        text-align: center;
        padding-top: $line-height-computed;

        h1 {
            font-size: $font-size-hero;
            color: $white;
            font-weight: normal;
            margin: 0;

            @media (min-width: $screen-sm-min) {
                font-size: floor(($font-size-base * 3.75));
            }
        }
    }

    &.with-form {
        .page-header-banner-title {
            padding-top: 0;
            padding-bottom: $line-height-computed;

            @media (min-width: $screen-sm-min) {
                padding: 0 ($grid-gutter-width/2);
                text-align: left;
                position: absolute;
                bottom: 20px;
                left: (100% / $grid-columns) * 7;
                right: 0;
                width: auto;
                margin: 0;
            }

            @media (min-width: $screen-md-min) {
                padding: 0 ($grid-gutter-width/2) 0 $grid-gutter-width;
                bottom: 35px;
                left: (100% / $grid-columns) * 6;
            }

            @media (min-width: $screen-lg-min) {
                left: (100% / $grid-columns) * 4;
            }
        }
    }

    &.without-form {
        min-height: 200px;

        @media (min-width: $screen-sm-min) {
            min-height: 300px;
        }

        @media (min-width: $screen-md-min) {
            min-height: 500px;
        }

        &.header-404 {
            background-image: url("../img/404-header.jpg");
            background-position: 80% center;

            @media (min-width: $screen-md-min) {
                min-height: 431px;
            }
        }

        &.small-header {
            min-height: 100px !important;

            @media (min-width: $screen-sm-min) {
                min-height: 200px !important;
            }

            @media (min-width: $screen-md-min) {
                min-height: 300px !important;
            }
        }
    }

    &.contact {
        $_triangle-width: 63px;
        $_triangle-height: 56px;

        position: relative;
        margin-bottom: $_triangle-height;

        h1 {
            @extend %_white-banner-title;
            margin-bottom: $grid-gutter-width/2;

            @media (min-width: $screen-md-min) {
                margin-bottom: $grid-gutter-width;
            }
        }

        a {
            color: $white;
        }

        &:after {
            @include horizontal-center();
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: $_triangle-height $_triangle-width 0 $_triangle-width;
            border-color: $orange transparent transparent transparent;
            position: absolute;
            top: 100%;
        }
        //
        //  Big phone number styling
        // ---------------------------------------
        .phone {
            font-size: $font-size-hero;

            strong {
                font-weight: normal;
            }
        }
        //
        //  Icons on the Contact us page
        // ---------------------------------------
        .icon-links {
            padding-top: 10px;
        }

        .icon-link {
            display: block;
            color: $gray-lighter;
            margin-bottom: $grid-gutter-width/2;

            .fa {
                width: 77px;
                height: 77px;
                border-radius: 100%;
                border: 1px solid $gray-lighter;
                position: relative;

                &:before {
                    @include all-center();
                    position: absolute;
                }
            }

            .text {
                display: block;
                margin-top: 6px;
                text-transform: uppercase;
                font-weight: $font-weight-bold;
            }

            &:hover {
                color: $orange;
                text-decoration: none;

                .fa {
                    border-color: $orange;
                }
            }

            &.active {
                color: $orange;

                .fa {
                    border-color: $orange;
                    background: $orange;
                    color: $white;
                }
            }

            @media (max-width: $screen-xs-max) {
                margin-bottom: $grid-gutter-width;
            }
        }
        //
        //  Contact location information
        // ---------------------------------------
        .contact-info-container {
            display: inline-block;
        }

        .contact-info {
            > i {
                display: inline-block;
            }

            > a {
                @include wrap-links();
            }

            &.phone {
                > i {
                    margin-right: 10px;
                }
            }

            &:not(:last-of-type) {
                margin-bottom: 10px;
            }

            &:not(.phone) {
                > i {
                    font-size: 1.5em;
                    width: 35px;
                    text-align: center;
                    margin-right: 20px;
                }
            }

            @media (max-width: $screen-xs-max) {
                &.phone {
                    font-size: $font-size-h2;

                    > i {
                        margin-right: 5px;
                    }
                }

                &:not(.phone) {
                    $_icon-width: 25px;
                    $_icon-margin: 15px;

                    position: relative;
                    padding-left: $_icon-width + $_icon-margin;

                    > i {
                        @include vertical-center();
                        position: absolute;
                        left: 0;
                        font-size: 1.1em;
                        width: $_icon-width;
                        text-align: center;
                        margin-right: $_icon-margin;
                    }
                }
                //&.email,
                //&.location {
                //    text-align: center;
                //
                //    > i {
                //        display: block;
                //        width: auto;
                //        margin: 0 0 5px 0;
                //    }
                //}
            }
        }

        .contact-quote-cta-container {
            margin-top: $grid-gutter-width;
        }
    }

    &.thankyou {
        color: $white;
        //margin-top: -60px;
        background-image: url('/ResourcePackages/Allied/assets/au-dist/img/australian-locations-allied-pickfords-contact-banner.jpg');

        h1 {
            @extend %_white-banner-title;

            .name {
                font-style: italic;
                text-transform: none;
            }
        }

        .received {
            font-size: $font-size-h3;
        }

        .icon-holder {
            margin: 30px 0;

            > .fa {
                background: $orange;
                border-radius: 100%;
                width: 100px;
                height: 100px;
                line-height: 100px;
            }
        }

        .contact {
            font-size: $font-size-h4;
            color: $orange;

            .highlight {
                color: $white;
            }
        }
    }

    .padded-container {
        padding-top: 20px;
        padding-bottom: 20px;

        @media (min-width: $screen-md) {
            padding-top: 60px;
            padding-bottom: 35px;
        }
    }

    .dark-container {
        @extend %dark-container;
    }
}