.partner-logos {
    margin: 0;
    display: inline-block;

    @each $logo-name, $logo-map in $logos {
        .#{$logo-name} {
            display: inline-block;
            vertical-align: middle;

            a {
                @include logo-sprite($logo-name);
            }
        }
    }
}