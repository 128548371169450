
// Fixed position "Get a quote" tab

.quote-tab {
	top: 10%;
	position: fixed;
    z-index: $zindex-popover;

	@media (max-width: $screen-xs-max) {
		display: none;
	}

    @media (max-width: $screen-sm-max) {
        @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
    }
	
	&.right {
		transition: right .2s;
		transform-origin: 100% 100%;
		transform: rotate(-90deg);
		right: -70px;

		&.in {
			right: 0;
		}
	}
	
	&.left {
		transition: left .2s;
		transform-origin: 0% 100%;
		transform: rotate(90deg);
		left: -70px;

		&.in {
			left: 0;
		}
	}
	
	i {
		margin-left: 5px;	
	}
}

