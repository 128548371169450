//
//  Variables
// ---------------------------------------
$_country-select-width: 80px;
$_country-right-offset: 5px;

//
//  Place holders
// ---------------------------------------
%_color-orange {
    color: $orange;
}
%_error-on-field {
    left: $grid-gutter-width/2;
    right: $grid-gutter-width/2;
    border-radius: 0 0 $border-radius-small $border-radius-small;
}

//
//  Styles
// ---------------------------------------
.quote-form {
    transition: background .3s;
    position: relative;
    z-index: $z-index--quote-form;

    > section {
        display: none;

        &:nth-child(1) {
            display: block;
        }
    }

    .form-section { 
        @extend %dark-container;
    }

    .slick-slide {
        display: block;

        &:focus {
            outline: none;
        }
    }

    .form-section-title {
        @media (max-width: $screen-xs-max) {
            font-size: $font-size-h3;
        }
    }

    .form-section-title-storage {
        margin-bottom: $form-group-margin-bottom * 1.5;
    }

    header {
        font-weight: $font-weight-bold;

        &.margin-bottom-lg {
            margin-bottom: $padding-large-vertical;
        }
    }

    .form-error {
        position: absolute;
        left: $grid-gutter-width;
        right: $grid-gutter-width;
        background: $brand-danger;
        color: $white;
        margin-top: -23px;
        padding: $padding-xs-vertical;
        border-radius: $border-radius-small;
    }

    .step-controller-bar {
        position: relative;

        .form-section-title {
            margin: 1em 0;
        }

        @media (min-width: $screen-sm-min) {
            .btn-back {
                @include vertical-center();
                position: absolute;
                left: 0;
            }

            .form-section-title {
                padding: 0 19%;
            }
        }
    }

    .form-section-title {
        color: $white;
        text-transform: uppercase;
        margin-top: 0;
    }

    .step-label {
        @extend %_color-orange;
    }

    .phone {
        @extend %phone-number;
        color: $white;
        margin-top: round($line-height-computed/2);

        > a {
            color: inherit;
        }
    }

    .phone-bottom-right {
        position: relative;
        z-index: 10;
        text-align: center;

        @media (min-width: $screen-md) {
            text-align: right;
        }

        .phone {
            margin-bottom: 0;
        }
    }

    .partner-logos {
        @each $logo-name, $logo-map in $logos {
            .#{$logo-name} a {
                @include resize-sprite($logo-name, .75);
            }
        }
    }

    .option-radio-label {
        cursor: pointer;
        display: block;
    }

    .step-option-icon {
        max-width: 100px;
        margin: 0 auto;
    }

    .step-radio-options {
        > li {
            font-size: .8em;

            input[type="radio"] {
                position: absolute;
                opacity: 0;
                text-indent: -9999;

                &:checked + label {
                    @extend %_color-orange;
                }
            }
        }

        @media (min-width: $screen-sm) {
            > li {
                padding: 0 round($grid-gutter-width/2);
                font-size: 1em;
            }
        }
    }

    .form-group {
        label {
            @extend %_color-orange;
        }
    }

    .moving-country {
        display: none;
        max-width: $_country-select-width;
        background: $gray-light;
        color: $white;
        border: transparent;
        position: absolute;
        top: 37px;
        right: $grid-gutter-width - $_country-right-offset;
    }

    //
    //  jQuery autocomplete
    // ---------------------------------------
    .ui-autocomplete-loading {
        background-image: url("../img/loader.gif");
        background-repeat: no-repeat;
        background-position: right 10px center;
    }

    &.show-country-select {
        .moving-country {
            display: block;
        }

        .moving-to,
        .moving-from {
            input[type="text"].form-control {
                padding-right: $_country-select-width + round($grid-gutter-width/2) - $_country-right-offset;
            }
        }

        .ui-autocomplete-loading {
            background-position: right (10px + $_country-select-width + round($grid-gutter-width/2) - $_country-right-offset) center;
        }
    }

    // Stops the country error message from appear over the top of the field one.
    .form-error + .form-group-sm > select + .form-error {
        display: none;
    }

    @import "step1";
    @import "step1B";
    @import "step2";
    @import "step3";
    @import "step4";
    @import "step5";
    @import "step1C";
    @import "step2C";

    @media (max-width: $screen-xs-max) {
        .step1B,
        .step2,
        .step5,
        .step1C,
        .step2C {
            .form-section {
                padding-left: round($padding-base-vertical/2);
                padding-right: round($padding-base-vertical/2);
            }

            .form-control {
                padding-left: round($padding-base-horizontal/2);
                padding-right: round($padding-base-horizontal/2);
            }
        }
    }
}