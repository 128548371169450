.site-footer {
    background: $gray-dark;
    padding: 50px 0 20px;

    .column-header {
        margin-top: 0;
        text-transform: uppercase;
    }

    [class^="col-"] {
        margin-bottom: $grid-gutter-width;

        @media (min-width: $screen-sm) {
            text-align: left;

            &.nav-contact {
                text-align: center;
            }
        }

        @media (min-width: $screen-md) {
            &.nav-contact {
                text-align: left;
            }
        }
    }

    .footer-column-nav {
        li {
            font-size: $font-size-h4;

            &.phone {
                @extend %phone-number;
            }

            &.quote {
                font-size: $font-size-h3;

                i {
                    font-size: 1em;
                    margin-right: 3px;
                }
            }

            > a {
                color: $white;
                display: block;
                padding: $padding-small-vertical 0 ;
                line-height: 1;
            }
        }
    }

    //
    //  Social links
    // ---------------------------------------
    .social-links {
        > a {
            position: relative;
            display: inline-block;
            border: 1px solid $link-color;
            border-radius: 100%;
            width: 34px;
            height: 34px;
            margin: 0 5px;

            &:hover {
                border-color: $link-hover-color;
            }

            > i {
                @include all-center();
                position: absolute;

                font-size: 1.4em;
            }
        }
    }

    //
    //  Footer logos
    // ---------------------------------------
    .footer-logos {
        text-align: center;
        padding: 0 0 30px;

        .list-inline {
            > li {
                padding: 15px;

                @media (min-width: $screen-sm) {
                    padding: 0 15px;
                }

                @media (min-width: $screen-md) {
                    padding: 0 20px;
                }
                @media (min-width: $screen-lg) {
                    padding: 0 30px;
                }
            }
        }
    }

    //
    //  Copyright
    // ---------------------------------------
    .copyright {
        color: $link-color;
        font-size: $font-size-small;
    }

    //
    //  Bottom Nav
    // ---------------------------------------
    .bottom-nav {
        li {
            position: relative;
            color: $white;
            padding: 0 10px;
            font-size: $font-size-small;

            &:after {
                content: " | ";
                color: inherit;
                position: absolute;
                right: -5px;
            }

            &:last-child {
                &:after {
                    content: "";
                }
            }

            > a {
                color: inherit;
            }
        }
    }
}

.grecaptcha-badge { visibility: hidden; }