
.content-section {
    margin-top: 30px;
    margin-bottom: 30px;

    & + & {
        margin-top: 30px;
    }

    @media (min-width: $screen-md) {

        margin-top: 30px;
        margin-bottom: 30px;

        & + & {
            margin-top: 60px;
        }
    }

    &.no-top-margin {
        margin-top: 0;
    }

    &.no-bottom-margin {
        margin-bottom: 0;
    }

    &.hidden {
        display: none;
    }

    &.double-margin {

        & + & {
            margin-top: 60px;
        }

        @media (min-width: $screen-md) {

            margin-top: 60px;
            margin-bottom: 60px;

            & + & {
                margin-top: 120px;
            }
        }
    }

    &:empty {
        margin-top: 0;
        margin-bottom: 0;
    }
}