

.testimonials {
	
	h3, .carousel {
		margin-bottom: 50px;	
	}
	
	@media (min-width: $screen-lg-desktop) {
		.carousel {
			margin-bottom: 30px;	
		}
	}
}

.carousel {
	width: 80%;
	margin: 0 auto;	
}

.testimonial {
	
	padding-left: 30px;
	padding-right: 30px;
	
	img {
		margin: 0 auto 20px;
		
		@media (max-width: $screen-xs-min) {
			width: 100%;
			height: auto;
		}
	}
	
	@media (min-width: $grid-float-breakpoint) {
	
		img, .testimonial-content {
			float: left;
		}
		
		img {
			width: 20%;
			margin: 0;
		}
		
		.testimonial-content {
			width: 80%;
			padding-left: 60px;
			padding-right: 30px;
		}
	}
	
	.quote, p, .title {
		display: block;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}
	
	p, .title  {
		width: 70%;
	}
	
	.quote {
		position: relative;
		background-color: #407dca;
		border-radius: 10px;
		color: #fff;
		text-transform: uppercase;
		font-size: 24px;
		width: 75%;
		padding: 20px;
		
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 17px;
			right: 100%;
			width: 0;
			height: 0;
			border-top: 20px solid #407dca;
			border-left: 48px solid transparent;	
		}
		
		@media (min-width: $screen-lg-desktop) {
			margin-bottom: 50px;
		}
	}
	
	.title {
		font-weight: 700;
		font-style: italic;
		color: $black;
		
		@media (min-width: $screen-lg-desktop) {
			margin-top: 30px;
		}
	}
}

/* Arrows */
.slick-prev, .slick-next {
	font-size: 0;
	line-height: 0;

	position: absolute;
	top: 50%;

	display: block;

	width: 20px;
	height: 20px;
	padding: 0;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);

	cursor: pointer;
	color: transparent;
	border: none;
	outline: none;
	background: transparent;
	
	&:hover, &:focus {
		
		color: transparent;
		outline: none;
		background: transparent;
		
		&:before {
			opacity: 1;
		}
	}
	
	&.slick-disabled:before {
		opacity: .25;
	}
	
	&:before {
		font-size: 35px;
		line-height: 20px;
	
		opacity: .75;
		color: black;
	
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}


.slick-prev {
	left: -25px;
	
	&:before {
		@extend .fa;
		@extend .fa-angle-left;
	}

	[dir='rtl'] & {
		right: -25px;
		left: auto;
		
		&:before {
			@extend .fa;
			@extend .fa-angle-right;
		}
	}
}

.slick-next {
	right: -25px;
	
	&:before {
		@extend .fa;
		@extend .fa-angle-right;
	}
	
	[dir='rtl'] & {
		right: auto;
		left: -25px;
		
		&:before {
			@extend .fa;
			@extend .fa-angle-left;
		}
	}
}



