// Uses bootstrap's navbar-default class and variables
.navbar {
    border: 0;

    @media (max-width: $grid-float-breakpoint-max) {
        li > a:not(.mobile-dropdown-toggle-btn) {
            $font-adjustment: 2px; // Adjustment for new font riding high

            border-bottom: 1px solid rgba($black, .10);
            padding-top: 10px + $font-adjustment !important;
            padding-bottom: 10px - $font-adjustment !important;
        }

        #primary-menu > li > a {
            background: $white;
        }
    }
}

.navbar-right {
    margin-right: 0;
}

.navbar-collapse {

    &.collapse.in {
        box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
    }

    @media (max-width: $grid-float-breakpoint-max) {
        $_new-navbar-height: 50px;

        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        border-top: 0;

        .sticky & {
            top: $_new-navbar-height + 5px;
        }
    }
}

//
//  Sticky default-navbar
// ---------------------------------------
.navbar-default {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 5px solid $orange;
    z-index: $zindex-tooltip;

    .navbar-brand,
    .navbar-nav > li > a,
    .navbar,
    .navbar-btn.btn-phone,
    .svg-alliedLogo,
    .svg-logoLines,
    .navbar-toggle {
        transition: all .3s;
    }
}

.site-header {
    padding-top: $navbar-height;
    transition: all .3s;

    &.sticky {
        @media (min-width: $screen-sm-min) {
            $_new-navbar-height: 50px;

            $_new-logo-width: 130px;
            $_new-logo-vertical-padding: round(($_new-navbar-height - getLogoHeight($_new-logo-width))/2);
            $_new-logo-horizontal-padding: round($grid-gutter-width/2);

            .navbar {
                min-height: $_new-navbar-height;
            }

            .navbar-toggle {
                height: $_new-navbar-height;
            }

            .navbar-btn.btn-phone {
                height: $_new-navbar-height;
                line-height: $_new-navbar-height;
                padding-top: 4px; // padding adjustment for font riding higher in the line
            }

            .navbar-brand {
                height: $_new-navbar-height;
                padding: $_new-logo-vertical-padding 0 $_new-logo-vertical-padding $_new-logo-horizontal-padding;

                .svg-alliedLogo {
                    @include logo-size($_new-logo-width);
                }

                .svg-logoLines {
                    left: ($_new-logo-width - $_new-logo-horizontal-padding + 5px) * -1;
                }
            }
        }

        @media (min-width: $screen-md-min) {
            $_new-navbar-height: 65px;
            $_new-navbar-item-vertical-padding: (($_new-navbar-height - $line-height-computed) / 2);

            $_new-logo-width: 150px;
            $_new-logo-vertical-padding: round(($_new-navbar-height - getLogoHeight($_new-logo-width))/2);
            $_new-logo-horizontal-padding: 60px;

            //
            //  White nav
            // ---------------------------------------
            .navbar {
                min-height: $_new-navbar-height;
            }

            .navbar-brand {
                height: $_new-navbar-height;
                padding: $_new-logo-vertical-padding 0 $_new-logo-vertical-padding $_new-logo-horizontal-padding;

                .svg-alliedLogo {
                    @include logo-size($_new-logo-width);
                    margin-left: $_new-logo-horizontal-padding * 2;
                }

                .svg-logoLines {
                    //left: ($_new-logo-width - $_new-logo-horizontal-padding) * -1;
                    left: -$_new-logo-horizontal-padding / 2;
                }
            }

            .navbar-nav > li > a {
                $font-adjustment: 2px; // Adjustment for new font riding high

                padding-top: $_new-navbar-item-vertical-padding + $font-adjustment;
                padding-bottom: $_new-navbar-item-vertical-padding - $font-adjustment;
            }

            .navbar-btn.btn-phone {
                height: $_new-navbar-height;
                line-height: $_new-navbar-height;
                padding-top: 4px; // padding adjustment for font riding higher in the line
            }
        }
    }
}

//
//  Logo
// ---------------------------------------
.navbar-brand {
    $_logo-width: 280px;
    $_logo-vertical-padding: round(($navbar-height - getLogoHeight($_logo-width))/2);
    $_logo--horizontal-padding: 106px;

    position: relative;
    padding: $_logo-vertical-padding 0 $_logo-vertical-padding $_logo--horizontal-padding;
    display: block;

    .svg-alliedLogo {

        @include logo-size($_logo-width);

        @media (min-width: $screen-lg-min) {
            margin-left: $_logo--horizontal-padding * 1.5;
        }
    }

    .svg-logoLines {
        position: absolute;
        bottom: 0;
        left: -$_logo--horizontal-padding / 2;
        height: 200%;
    }

    &.x-mas {
        &:before {
            @include hat-dimensions(3rem);
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: $zindex-tooltip;
            background: url("../img/santa-hat.svg") top right no-repeat;
            background-size: contain;
            transform: translate(18%,-1%) rotate(-12deg);
            transition: all .3s;

            .sticky & {
                @include hat-dimensions(1.65rem);
                transform: translate(12%,30%) rotate(-12deg);
            }
        }
    }
}

//
//  Menu toggle button
// ---------------------------------------
.navbar-toggle {
    background: $navbar-default-toggle-border-color;
    width: 44px;
    margin: 0;
    height: $navbar-height;

    .icon-bar {
        margin-left: auto;
        margin-right: auto;
    }
}

//
//  Phone number button
// ---------------------------------------
.navbar-btn.btn-phone {
    @extend %phone-number;
    margin-left: $navbar-padding-horizontal;
    border-radius: 0;
    border: 0;
    height: $navbar-height;
    margin-top: 0;
    margin-bottom: 0;
    padding: 5px 1em 0; // 3px padding top is adjustment for font riding higher in the line
    line-height: $navbar-height;

    .has-referrer &,
    .always-small-number &,
    .inventory-started & {
        font-size: $font-size-h5;

        @media (min-width: $screen-sm-min) {
            font-size: $font-size-h4;
        }

        @media (min-width: $screen-lg-min) {
            font-size: $font-size-h3;
        }
    }
}


// ============================================================================ //
//  Responsive navbar
//  (includes some styling for the orange nav)
// ============================================================================ //
//
//  Medium screen size when desktop menu in use
// ----------------------------------------------------------------------------
@media (min-width: $grid-float-breakpoint) and (max-width: $screen-md-max) {
    $_new-navbar-height: 70px;
    $_new-navbar-item-vertical-padding: (($_new-navbar-height - $line-height-computed) / 2);

    $_new-logo-width: 190px;
    $_new-logo-vertical-padding: round(($_new-navbar-height - getLogoHeight($_new-logo-width))/2);
    $_new-logo-horizontal-padding: 60px;

    //
    //  White nav
    // ---------------------------------------
    .navbar-brand {
        height: $_new-navbar-height;
        padding: $_new-logo-vertical-padding 0 $_new-logo-vertical-padding $_new-logo-horizontal-padding;

        .svg-alliedLogo {
            @include logo-size($_new-logo-width);
            margin-left: $_new-logo-horizontal-padding * 2;
        }

        .svg-logoLines {
            left: -$_new-logo-horizontal-padding / 1.5;
        }

        &.x-mas {
            &:before {
                @include hat-dimensions(2rem);
                transform: translate(20%,6%) rotate(-12deg);

                .sticky & {
                    @include hat-dimensions(1.65rem);
                    transform: translate(9%,26%) rotate(-12deg);
                }
            }
        }
    }

    .navbar-nav > li > a {
        $font-adjustment: 3px; // Adjustment for new font riding high
        padding: ($_new-navbar-item-vertical-padding + $font-adjustment) round($_new-navbar-item-vertical-padding/2) ($_new-navbar-item-vertical-padding - $font-adjustment);
    }

    .navbar-btn.btn-phone {
        font-size: $font-size-h3;
        height: $_new-navbar-height;
        line-height: $_new-navbar-height;
        padding-top: 4px; // padding adjustment for font riding higher in the line
    }

    .navbar {
        min-height: $_new-navbar-height;
    }

    @media (min-width: 1000px) and (max-width: 1299px) {
        .navbar {
            min-height: 70px;
        }
    }

    .site-header {
        padding-top: $_new-navbar-height;
    }

    //
    //  Orange nav
    // ---------------------------------------
    .nav-orange .nav > li {
        > a {
            padding-top: $_new-navbar-item-vertical-padding;
            padding-bottom: $_new-navbar-item-vertical-padding;
        }

        &.dropdown > a:before {
            margin-right: 8px;
        }
    }
}

@media (min-width: $screen-md-min) {
    .site-header {
        padding-top: 141px;
    }
}

@media (min-width: $screen-lg-min) {
    .site-header {
        padding-top: 202px;
    }
}

@media (min-width: $grid-float-breakpoint) and (max-width: $grid-float-breakpoint + 70) {
    .navbar-btn.btn-phone {
        padding: 4px .75em 0; // top padding adjustment for font riding higher in the line
        margin-left: 5px;
    }

    .navbar-nav > li > a {
        padding-left: 8px;
        padding-right: 8px;
    }
}

//
//  Extra small and small screens when mobile menu in use
// ----------------------------------------------------------------------------
@media (max-width: $grid-float-breakpoint-max) {
    $_new-navbar-height: 70px;
    $_new-navbar-item-vertical-padding: (($_new-navbar-height - $line-height-computed) / 2);

    $_new-logo-width: 190px;
    $_new-logo-vertical-padding: round(($_new-navbar-height - getLogoHeight($_new-logo-width))/2);
    $_new-logo-horizontal-padding: round($grid-gutter-width/2);

    .navbar-header {
        border-bottom: 5px solid $orange;
    }

    .navbar {
        min-height: $_new-navbar-height;
        border-bottom: none;

        .container {
            width: 100%;
        }

        > li > a {
            padding: $_new-navbar-item-vertical-padding round($_new-navbar-item-vertical-padding/2);
        }
    }

    .site-header {
        padding-top: $_new-navbar-height;
    }

    .navbar-nav {
        margin-top: 0;
        margin-bottom: 0;
    }

    .navbar-right {
        margin-right: round($grid-gutter-width/-2);
    }

    .navbar-toggle {
        height: $_new-navbar-height;
    }

    .navbar-brand {
        height: $_new-navbar-height;
        padding: $_new-logo-vertical-padding 0 $_new-logo-vertical-padding $_new-logo-horizontal-padding;

        .svg-alliedLogo {
            @include logo-size($_new-logo-width);
        }

        .svg-logoLines {
            display: none;
        }

        &.x-mas {
            &:before {
                @include hat-dimensions(2rem);
                transform: translate(20%,6%) rotate(-12deg);

                .sticky & {
                    @include hat-dimensions(1.35rem);
                    transform: translate(12%,24%) rotate(-12deg);
                }
            }
        }
    }

    .navbar-btn.btn-phone {
        font-size: $font-size-h3;
        height: $_new-navbar-height;
        line-height: $_new-navbar-height;
        padding-top: 4px; // padding adjustment for font riding higher in the line
    }

    .navbar-collapse {
        top: $_new-navbar-height + 5px;
    }
}

//
//  Extra small screen only
// ----------------------------------------------------------------------------
@media (max-width: $screen-xs-max) {
    $_new-navbar-height: 50px;

    $_new-logo-width: 100px;
    $_new-logo-vertical-padding: round(($_new-navbar-height - getLogoHeight($_new-logo-width))/2);
    $_new-logo-horizontal-padding: round($grid-gutter-width/2);

    .navbar-toggle {
        height: $_new-navbar-height;
    }

    .navbar-brand {
        height: $_new-navbar-height;
        padding: $_new-logo-vertical-padding 0 $_new-logo-vertical-padding $_new-logo-horizontal-padding;

        .svg-alliedLogo {
            @include logo-size($_new-logo-width);
        }

        .svg-logoLines {
            left: ($_new-logo-width - $_new-logo-horizontal-padding + 5px) * -1;
        }

        &.x-mas {
            &:before {
                @include hat-dimensions(1.35rem);
                transform: translate(12%,34%) rotate(-12deg);
            }
        }
    }

    .navbar-btn.btn-phone {
        font-size: $font-size-h4;
        height: $_new-navbar-height;
        line-height: $_new-navbar-height;
        padding-top: 3px; // padding adjustment for font riding higher in the line
    }

    .navbar {
        min-height: $_new-navbar-height;
        border-bottom: none;
    }

    .site-header {
        padding-top: $_new-navbar-height;
    }

    .navbar-collapse {
        top: $_new-navbar-height + 5px;
    }
}