// The styling for Latest Posts widget and BE Subpages Menu that get used in the sidebar.

//
//  Widget
// ---------------------------------------
.widget {
    .widget-title {
        color: $brand-primary;
        font-size: $font-size-h3;
    }

    .widget-title {
        text-transform: uppercase;
    }
}

//
//  Menu-esque widgets
// ---------------------------------------
%_side-menu {
    ul {
        @extend .list-unstyled;
        margin: 0;

        li {
            transition: all .3s;
            margin: 10px 0;
            overflow: hidden;

            > a {
                display: block;
                color: $gray-light;
            }
        }
    }
}

.widget_recent_entries {
    @extend %_side-menu;

    > ul {
        > li {

            &:nth-child(n+6) {
                max-height: 0;
                margin: 0;
            }
        }
    }

    .expand-for-more {
        text-align: left;

        &:before {
            @extend .pull-right;
            content: "+";
            font-size: 1.2em;
            line-height: .7;
        }
    }

    &.expanded {
        > ul {
            > li {
                &:nth-child(n+6) {
                    max-height: 100px;
                    margin: 10px 0;
                }
            }
        }

        .expand-for-more {
            &:before {
                content: "-";
            }
        }
    }
}

.widget_subpages {
    @extend %_side-menu;

    > ul {
        > li {
            &.widget_subpages_current_page {
                > a {
                    color: $orange;
                }
            }
        }
    }
}

.widget_categories {
    @extend %_side-menu;
    margin-bottom: $padding-large-vertical * 2;

    .expand-for-more {
        display: none;
    }

    .children {
        padding-left: $padding-small-horizontal;
    }

    li {
        position: relative;
    }

    .categories-count {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 14px;
        color: $gray-light;
    }

    a.expand,
    a.collapse,
    .collapscatlist-nothing,
    li > img {
        position: absolute;
        left: 0;
        top: 0;
    }

    a.expand,
    a.collapse {
        color: $gray-lighter;

        + a {
            padding-left: round($padding-base-horizontal * .75);
        }

        ~ .children {
            padding-left: $padding-base-horizontal;
        }
    }
}