// ============================================================================ //
//  Step 3 - choose house size
// ============================================================================ //
//
//  Place holders
// ---------------------------------------
%_step3-unselected-icon-fill {
    fill: $gray-lighter;
}
%_step3-selected-icon-fill {
    fill: $orange;
}
%_step3-unselected-icon-stroke {
    stroke: $gray-lighter;
}
%_step3-selected-icon-stroke {
    stroke: $orange;
}

//
//  Styling
// ---------------------------------------
.step3 {
    .form-error {
        margin-top: -11px;
    }

    @media (min-width: $screen-md-min) {
        .margin-bottom-lg {
            margin-bottom: $padding-large-vertical * 2;
        }

        .btn-primary {
            margin-top: 20px;
        }
    }

    @media (max-width: $screen-xs-max) {
        .options-column {
            padding: 0;
        }
    }
}

.step3-options {
    // Each li contains the radio option and label
    > li {
        input[type="radio"] {
            + label {
                // 1 Bedroom
                .svg-bedroom-1-roof,
                .svg-bedroom-1-door-windows{
                    @extend %_step3-unselected-icon-fill;
                }
                .svg-bedroom-1-doorstop,
                .svg-bedroom-1-walls-ground {
                    @extend %_step3-unselected-icon-stroke;
                }

                // 2-3 Bedroom
                .svg-bedroom-2-roof,
                .svg-bedroom-2-door-windows{
                    @extend %_step3-unselected-icon-fill;
                }
                .svg-bedroom-2-doorstop,
                .svg-bedroom-2-walls-ground {
                    @extend %_step3-unselected-icon-stroke;
                }

                // 4+ Bedroom
                .svg-bedroom-3-roof,
                .svg-bedroom-3-door-windows{
                    @extend %_step3-unselected-icon-fill;
                }
                .svg-bedroom-3-doorstop,
                .svg-bedroom-3-walls-ground {
                    @extend %_step3-unselected-icon-stroke;
                }

                // Just a few items
                .svg-boxes-top {
                    fill: transparent;
                }
                .svg-boxes-bottom {
                    @extend %_step3-unselected-icon-fill;
                }
            }

            &:checked + label {
                // 1 Bedroom
                .svg-bedroom-1-roof,
                .svg-bedroom-1-door-windows{
                    @extend %_step3-selected-icon-fill;
                }
                .svg-bedroom-1-doorstop,
                .svg-bedroom-1-walls-ground {
                    @extend %_step3-selected-icon-stroke;
                }

                // 2-3 Bedroom
                .svg-bedroom-2-roof,
                .svg-bedroom-2-door-windows{
                    @extend %_step3-selected-icon-fill;
                }
                .svg-bedroom-2-doorstop,
                .svg-bedroom-2-walls-ground {
                    @extend %_step3-selected-icon-stroke;
                }

                // 4+ Bedroom
                .svg-bedroom-3-roof,
                .svg-bedroom-3-door-windows{
                    @extend %_step3-selected-icon-fill;
                }
                .svg-bedroom-3-doorstop,
                .svg-bedroom-3-walls-ground {
                    @extend %_step3-selected-icon-stroke;
                }

                // Just a few items
                .svg-boxes-bottom {
                    @extend %_step3-selected-icon-fill;
                }
            }
        }

        @media (max-width: $screen-xs-max) {
            &:nth-child(1),
            &:nth-child(2) {
                margin-bottom: 20px;
            }
        }
    }

    .step-option-icon {
        max-width: 160px;

        > svg {
            width: auto !important;
        }
    }

    .icon-bedroom-1 {
        padding-top: 15px;

        > svg {
            height: 63px !important;
        }

        @media (min-width: $screen-sm-min) {
            padding-top: 22px;

            > svg {
                height: 77px !important;
            }
        }
    }

    .icon-bedroom-2 {
        padding-top: 15px;

        > svg {
            height: 63px !important;
        }

        @media (min-width: $screen-sm-min) {
            padding-top: 22px;

            > svg {
                height: 77px !important;
            }
        }
    }

    .icon-bedroom-3 {
        padding-top: 7px;

        > svg {
            height: 77px !important;
        }

        @media (min-width: $screen-sm-min) {
            > svg {
                height: 92px !important;
            }
        }
    }

    .icon-few-items {
        padding-top: 20px;

        > svg {
            height: 63px !important;
        }

        @media (min-width: $screen-sm-min) {
            padding-top: 22px;

            > svg {
                height: 77px !important;
            }
        }
    }
}