

.btn {
    $btn-font-adjustment: 2px;

    font-size: $font-size-h3;
    line-height: 1;
    padding-top: $padding-base-vertical + $btn-font-adjustment; // Adjustment for weird height of new font.
    padding-bottom: $padding-base-vertical - $btn-font-adjustment; // Adjustment for weird height of new font.

    &.btn-lg {
        $btn-font-adjustment: 3px;

        font-size: $font-size-h2;
        padding-top: $padding-large-vertical + $btn-font-adjustment; // Adjustment for weird height of new font.
        padding-bottom: $padding-large-vertical - $btn-font-adjustment; // Adjustment for weird height of new font.
    }

    &.btn-sm {
        $btn-font-adjustment: 1px;

        font-size: $font-size-h5;
        padding-top: $padding-small-vertical + $btn-font-adjustment; // Adjustment for weird height of new font.
        padding-bottom: $padding-small-vertical - $btn-font-adjustment; // Adjustment for weird height of new font.
    }

    &.btn-xs {
        $btn-font-adjustment: 1px;

        font-size: $font-size-small;
        padding-top: $padding-small-vertical + $btn-font-adjustment; // Adjustment for weird height of new font.
        padding-bottom: $padding-small-vertical - $btn-font-adjustment; // Adjustment for weird height of new font.
    }

    &.loading {
        &:before {
            @extend .fa;
            @extend .fa-circle-o-notch;
            @extend .fa-spin;
            margin-right: 10px;
            font-size: .8125em;
        }
    }
}

.form-control {
    font-size: $font-size-h4;

    &.input-lg {
        font-size: $font-size-h3;
    }

    &.input-sm {
        font-size: $font-size-h5;
    }
}

ul.ticks {
	
	text-align: left;
	list-style: none;
	
	li {
		margin-bottom: 11px;
		position: relative;
		
		&:before {
			
			content: "\F00c";
			font-family: fontawesome;
			position: absolute;
			left: -46px ;
			top: 0;
			color: $blue;
		}
	}
}