// ============================================================================ //
//  Custom theme variables
// ============================================================================ //
//
//  Colors
// ---------------------------------------
$white:         #fff;
$black:         #000;

$orange:        #f58124;
$blue:          #407dca;
$yellow:        #fdd51c;
$red:           #e80025;

//
//  Typography
// ---------------------------------------
$phone-icon-font-size:    .825em;

// ============================================================================ //
//  Overwriting Bootstrap defaults
// ============================================================================ //
//
//  Colors
// ---------------------------------------
$gray-base:     #050708;
$gray-darker:   #23242a;
$gray-dark:     #2e3037;
$gray:          #313131;
$gray-light:    #818181;
$gray-lighter:  #bbb9b4;
$gray-lightest: #efefef;

$brand-primary:     $blue;
$brand-warning:     $orange;
$brand-danger:      $red;
$brand-info:        $blue;

//
//  Form states and alerts
// ---------------------------------------
//## Define colors for form feedback states and, by default, alerts.
$state-danger-text:              $brand-danger;
$state-danger-bg:                $brand-danger;
$state-danger-border:            $brand-danger;

//
//  Scaffolding
// ---------------------------------------
$text-color:            $black;
$link-color:            $orange;

//
//  Typography
// ---------------------------------------
$font-family-sans-serif: "Hind_Gunt Regular",Helvetica,Arial,sans-serif;

$font-size-base:          16px;

$font-weight-bold:        600;

$headings-font-weight:    600;
$headings-color:          $orange;
$headings-color2:         $black;

$font-size-h1:            floor(($font-size-base * 1.875));  // ~30px
$font-size-h2:            floor(($font-size-base * 1.875)); // ~30px
$font-size-h3:            ceil(($font-size-base * 1.5));    // ~24px
$font-size-h4:            ceil(($font-size-base * 1.125));  // ~18px
$font-size-h5:            $font-size-base;
$font-size-h6:            ceil(($font-size-base * 0.75));   // ~12px

// New font site
$font-size-hero:            ceil(($font-size-base * 2.75));   // ~44px

$line-height-base:        1.428571429;
$line-height-computed:    floor(($font-size-base * $line-height-base));

//
//  Media queries breakpoints
// ---------------------------------------
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs:                  480px;
$screen-xs-min:              $screen-xs;
$screen-phone:               $screen-xs-min;

// Small screen / tablet
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
$screen-md:                  1000px;
$screen-md-min:              $screen-md;
$screen-desktop:             $screen-md-min;

// Large screen / wide desktop
$screen-lg:                  1300px;
$screen-lg-min:              $screen-lg;
$screen-lg-desktop:          $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);

//
//  Grid system
// ---------------------------------------
//  Number of columns in the grid.
$grid-columns:              12;
//  Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         30px;
// Navbar collapse
//  Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-md-min;
//  Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);

//
//  Container sizes
// ---------------------------------------
//## Define the maximum width of `.container` for different screen sizes.
$container-tablet:             ($screen-sm - $grid-gutter-width);
$container-sm:                 $container-tablet;

$container-desktop:            ($screen-md - $grid-gutter-width);
$container-md:                 $container-desktop;

$container-large-desktop:      ($screen-lg - $grid-gutter-width);
$container-lg:                 $container-large-desktop;

//
//  Navbar
// ---------------------------------------
// Basics of a navbar
$navbar-height:                    100px;
$navbar-margin-bottom:             0;
$navbar-border-radius:             0;
$navbar-padding-horizontal:        15px;
//$navbar-padding-vertical:          (($navbar-height - $line-height-computed) / 2) !default;
//$navbar-collapse-max-height:       340px !default;
//
$navbar-default-color:             $black;
$navbar-default-bg:                $white;

// Navbar links
$navbar-default-link-color:                $navbar-default-color;
$navbar-default-link-hover-color:          $navbar-default-color;
$navbar-default-link-hover-bg:             mix($black, $navbar-default-bg, 5%);
$navbar-default-link-active-color:         $orange;
$navbar-default-link-active-bg:            $navbar-default-bg;
//$navbar-default-link-disabled-color:       #ccc !default;
//$navbar-default-link-disabled-bg:          transparent !default;
//
//// Navbar brand label
//$navbar-default-brand-color:               $navbar-default-link-color !default;
//$navbar-default-brand-hover-color:         darken($navbar-default-brand-color, 10%) !default;
//$navbar-default-brand-hover-bg:            transparent !default;

$nav-font-adjustment:                       2px; // Adjustment for new font riding high
$nav-link-padding:                          (10px + $nav-font-adjustment) 15px (10px - $nav-font-adjustment);

// Navbar toggle
$navbar-default-toggle-hover-bg:           $gray;
$navbar-default-toggle-icon-bar-bg:        $white;
$navbar-default-toggle-border-color:       $gray-darker;

//
//  Components
// ---------------------------------------
$border-radius-base:        0;
$border-radius-large:       10px;
$border-radius-small:       4px;

$padding-base-vertical:     20px;
$padding-base-horizontal:   30px;

$padding-large-vertical:    20px;
$padding-large-horizontal:  30px;

$padding-small-vertical:    10px;
$padding-small-horizontal:  20px;

$padding-xs-vertical:       5px;
$padding-xs-horizontal:     10px;

$progress-border-radius:    4px;

//
//  Buttons
// ---------------------------------------
$btn-font-weight:                600;

$btn-default-color:              $white;
$btn-default-bg:                 #c3c5c7;
$btn-default-border:            darken($btn-default-bg, 2%);

$btn-border-radius-base:         0;
$btn-border-radius-large:        0;
$btn-border-radius-small:        0;

//
//  Forms
// ---------------------------------------
$input-border-radius:            0;
$input-border-radius-large:      0;
$input-border-radius-small:      0;

