.post-author {
    margin-top: 20px;
    text-align: left;

    > img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto 10px;
    }

    > .post-author-details {
        text-align: left;

        .post-author-name {
            color: $headings-color2;
        }

        .post-author-position {
            display: block;
            margin-top: 5px;
            font-size: .9em;
            color: $text-muted;
        }
    }
}