//
//  Post content
// ---------------------------------------
.single-post {
    @extend %post-view;

    .post-img {
        background: $gray-lightest no-repeat center center;
        background-size: cover;
        box-sizing: content-box;
        padding-bottom: 40%;
        position: relative;

        @media (min-width: $screen-lg-min) {
            height: 500px;
            padding-bottom: 0;
            box-sizing: border-box;
        }
    }

    //
    //  Share icons
    // ---------------------------------------
    // Using plugin "WP Font Awesome Share Icons"
    ul.wpfai-list {
        background: $btn-default-bg;
        color: $btn-default-color;
        padding: round($padding-base-vertical/2) $padding-base-horizontal;
        text-align: right;

        @media (max-width: $screen-xs-max) {
            text-align: center;
            padding-left: 0;
            padding-right: 0;

            > li {
                margin: 0 10px;
            }
        }

        &:before {
            content: "Share on social media";
            font-weight: $font-weight-bold;
            float: left;
            line-height: 32px;
            display: block;

            @media (max-width: $screen-xs-max) {
                float: none;
            }
        }

        a.wpfai-link {
            color: $btn-default-color;
        }
    }
}