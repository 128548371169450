// ============================================================================ //
//  Step 2 - fill in from and to
// ============================================================================ //

//
//  Styling
// ---------------------------------------
.step2C {
    .fa-arrow-right,
    .fa-arrow-down {
        @extend %_color-orange;
    }

    .form-error {
        @extend %_error-on-field;
        margin-top: -6px;
    }

    .fa-arrow-down {
        display: block;
    }

    .date-picker-column {
        margin-bottom: 26px;

        .form-error {
            border-radius: $border-radius-small;
            margin-top: -10px;
        }

        @media (max-width: $screen-xs-max) {
            margin-top: 42px;
        }
    }

    footer {
        @media (min-width: $screen-sm-min) {
            text-align: left;
        }
    }
}
