.step1C {
    .form-group {
        position: relative;

        label {
            @extend %label-over-field;
        }

        .form-control {
            @include field-with-label-over() {
                padding-left: 95px;
            }
        }
    }

    .form-group {
        @extend %stacked-quote-fields;
    }

    .form-error {
        @extend %_error-on-field;
        margin-top: -6px;
    }

    .phone {
        text-align: center;

        @media (min-width: $screen-sm-min) {
            text-align: left;
        }
    }
}