%phone-number {
    font-size: $font-size-h2;

    i {
        font-size: $phone-icon-font-size;
    }
}

%dark-container {
    padding: $padding-base-horizontal $padding-base-vertical; // Inverted values on purpose
    background: rgba($gray-darker, .8);
    color: $white;
    text-align: center;
    border-radius: $border-radius-large;
    overflow: hidden;

    h3 {
        color: $orange;
        margin-top: 0;
        text-transform: none;
        font-weight: normal;
    }
}

$_field-label-over-min: 370px;

%label-over-field {
    text-transform: uppercase;
    position: absolute;
    left: $grid-gutter-width;
    top: 3px;

    @media (min-width: $_field-label-over-min) {
        left: $grid-gutter-width/2 + 10px;
        top: 22px;
    }
}

@mixin field-with-label-over() {
    @media (min-width: $_field-label-over-min) {
        @content;
    }
}

%stacked-quote-fields {
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
    margin-bottom: $grid-gutter-width;
}

%post-view {
    .entry-header {
        margin-bottom: $grid-gutter-width;

        @media (min-width: $screen-md-min) {
            margin-top: $line-height-computed;
        }
    }

    .entry-title {
        color: $text-color;
        margin-bottom: 0;
    }
}