// Specific styles for content blocks

.worlds-oldest-most-trusted-movers {
	
	background-color: #bbb9b4;
	background-image: url(/wp-content/uploads/2016/07/allied-pickfords-worlds-oldest-most-trusted-movers.jpg);
	background-repeat: no-repeat;
	background-position: left;
	text-align: center;
	
	@media (min-width: $screen-sm-min) {
		
		text-align: right;
		min-height: 501px;
	}
	
	@media (min-width: $screen-md-min) {
		
		background-size: auto;
	}
	
	.black-shadow {
		
		background-color: rgba(35, 36, 42, 0.8);
		
		@media (min-width: $screen-lg-min) {

			background-color: transparent;
			min-height: auto;	
		}
		
		@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {

			padding-bottom: 41px;
		}
	}
	
	.container {
		padding-top: 50px;
	}

	h1 {
		color: $orange;
		font-size: 32px;

		@media (min-width: $screen-sm-min) {
			font-size: 48px;
		}
		
		@media (min-width: $screen-lg-min) {
			color: $black;
		}
	}
	
	p {
		color: $white;
		font-size: 24px;
		text-shadow: 1px 1px rgba(0,0,0,0.3);
		
		margin: 20px 0 30px;
		
		@media (min-width: $screen-md-min) {
			margin: 40px 0;
		}

	}
}


.convenient-services {
	background-color: $orange;
	text-align: center;
	padding: 18px 0 29px;
	
	h2, h3 a {
		color: $white;
	}
	
	h2 {
		text-transform: uppercase;
		margin-bottom: 40px;
	}
	
	h3 a {
		text-transform: capitalize;	
	}
	
	h3 {
		margin-bottom: 30px;
		
		@media (min-width: $screen-sm-min) {
			margin-bottom: 11px;
		}
	}
}

.about-icon-navigation {
	
	padding: 30px 0;
	
	background-color: $orange;
	
	.content-section {
		margin-left: auto;
		margin-right: auto;
		
		@media (min-width: $screen-md-min) {
			width: 60%;
		}
		
		.row {
			margin-top: 30px;
			margin-bottom: 30px;	
		}
	}
	
	a {
		color: $white;
	}
}

.about-numbers {
	
	background-color: $orange;
	padding: 80px 0 64px;
	
	.figure, .item {
		color: $white;
	}
	
	.figure {
		font-size: 60px;	
	}
	
	.item {
		font-size: 18px;
		margin-top: 11px;	
	}
}

.moving-can-be-stressful {
	
	background-color: $blue;
	padding: 34px 0 35px;
	
	h2, p, a {
		color: $white;	
	}
	
	h2 {
		font-size: 36px;
		text-transform: uppercase;
		margin-top: 30px;
	}
	
	.icons {
		
		@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
			padding-top: 39px;
		}
		
	}
}


.everything-from-the-quote {
	
	background-color: #313131;
	background-image: url(/wp-content/uploads/2016/07/everything-from-the-quote.jpg);
	background-repeat: no-repeat;
	background-position: left top;
	padding-top: 36px;
	text-align: center;

	@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
		background-size: auto 600px;
	}

	@media (min-width: $screen-md-min) {
		text-align: right;
	}
	
	.container {
		position: relative;
	}

	.foreground {
		background-image: url(/wp-content/uploads/2016/07/sonja.png);
		background-repeat: no-repeat;
		width: 420px;
		height: 590px;
		position: absolute;
		bottom: 0;
		left: 0;
	}
	
	h2, p {
		
		text-shadow: 1px 1px rgba(0,0,0,0.3);
		
		@media (min-width: $screen-md-min) {
			text-shadow: none;
		}
	}

	h2 {
		color: $orange;
		font-size: 35px;
		text-transform: uppercase;
		
		@media (min-width: $screen-md-min) {
			color: $blue;
		}
	}
	
	p {
		color: $white;
		font-size: 24px;
		margin: 20px 0 30px;
	}
	
	.name {
		font-style: italic;
		font-weight: 700;
	}
	
	.btn {
		margin-bottom: 5px;	
	}

	@media (max-width: $screen-xs-max) {
		.foreground {
			width: 100%;
			max-width: 420px;
		}

		.pull-right {
			background: linear-gradient(to bottom, rgba($gray-darker, 0), $gray-darker);
		}
	}
}


.worlds-most-trusted-movers {
	
	background-color: #888c8f;
	text-align: center;

	@media (min-width: $screen-md-min) {
		text-align: right;
	}
	
	.white-top-background {
		background-image: url(/wp-content/uploads/2016/07/white-top-background.png);
		background-repeat: repeat-x;
	}
	
	.foreground {
		background-image: url(/wp-content/uploads/2016/07/truck.jpg);
		background-repeat: no-repeat;
		background-position: left top;
		background-size: cover;
		
		@media (min-width: $screen-md-min) {
			background-size: auto;	
		}
	}
	
	.foreground, .white-fade {
		min-height: 552px;
	}
	
	.white-fade {
		background-color: rgba(255, 255, 255, 0.5);
		
		@media (min-width: $screen-md-min) {
			background-color: transparent;
		}
	}
	
	.container {
		padding-top: 90px;	
	}

	h2 {
		color: $black;
		font-size: 35px;
		text-transform: uppercase;
	}
	
	p {
		font-size: 24px;
		margin: 20px 0 30px;
		text-shadow: 1px 1px rgba(0,0,0,0.3);
		color: $black;
		
		@media (min-width: $screen-md-min) {
			color: $white;
		}
		
		@media (min-width: $screen-lg-min) {
			text-shadow: none;
		}
	}
	
	.btn {
		margin-bottom: 5px;	
	}
}


.free-storage {
	
	background-color: #f58124;
	background-image: url(/wp-content/uploads/2016/07/free-storage.jpg);
	background-repeat: no-repeat;
	background-position: left top;
	text-align: center;
	min-height: 501px;
	margin-bottom: 60px;

	@media (min-width: $screen-md-min) {
		text-align: right;
		background-size: auto;
	}
	
	.container {
		position: relative;
	}

	.foreground {
		background-image: url(/wp-content/uploads/2016/07/free-storage-crate.png);
		background-repeat: no-repeat;
		width: 811px;
		height: 556px;
		position: absolute;
		top: 0;
		left: 0;
	}
	
	.copy {
		padding-top: 78px;	
	}
	
	h2, p {
		
		text-shadow: 1px 1px rgba(0,0,0,0.3);
		
		@media (min-width: $screen-md-min) {
			text-shadow: none;
		}
	}

	h2 {
		color: $white;
		font-size: 35px;
		text-transform: uppercase;
		font-size: 49px;
		
		.large {
			font-size: 72px;
			line-height: 92px;	
		}
	}
	
	p {
		color: $white;
		font-size: 24px;
		margin: 20px 0 30px;
	}
	
	.btn {
		margin-bottom: 5px;	
	}

	@media (max-width: 900px) {
		.foreground {
			width: 100%;
			max-width: 811px;
		}
	}
}