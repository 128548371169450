
.contact-nav-tabs {
	
	margin-top: 50px;
	margin-bottom: 20px;
	border-top: 1px solid $gray !important;
	border-left: 1px solid $gray !important;
	border-bottom: 1px solid $gray !important;
	
	a {
		border-right: 1px solid $gray !important;
		border-top: none !important;
		border-bottom: none !important;
		color: $black;
		
		&:hover {
			background-color: $gray-lightest !important;	
		}
	}
	
	li.active {
		
		background-color: $black;
	
		& > a {
			background-color: $black !important;
			color: $white !important;
			border-bottom: 1px solid $gray !important;
			border-top: none !important;
			border-left: none !important;
			border-right: none !important;
		}
	}

    &.nav-tabs > li > a {
        border-bottom: none !important;

        &:hover {
            border-color: transparent;
        }
    }
}


.contact-locations-tabs {
    .tab-pane {
        padding-top: $grid-gutter-width;
    }
}

.contact-locations {
	
	&.simple {
		
		text-align: left;
		
		p {
			padding-left: 40px;	
		}
		
		a {
			color: $black;	
		}
	}
	
    .location {
        margin-bottom: $grid-gutter-width;
        min-height: 300px;

        a {
            font-weight: $font-weight-bold;

            &.quote {
                text-transform: uppercase;
            }
        }

        p {
            text-align: left;
            position: relative;
            margin: 16px 0 0 30px;

            &:before {
                content: '\f041';
                font-family: fontawesome;
                color: $orange;
                font-size: 26px;
                position: absolute;
                top: -11px;
                left: -26px;
            }
        }

        @media (max-width: $screen-xs-max) {
            min-height: 0;

            a {
                display: block;

                + br {
                    display: none;
                }
            }

            img, p {
                display: inline-block;
            }
        }
    }
}