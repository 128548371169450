// ============================================================================ //
//  Step 2 - fill in from and to
// ============================================================================ //

//
//  Styling
// ---------------------------------------
.step2 {
    .fa-arrow-right,
    .fa-arrow-down {
        @extend %_color-orange;
    }

    .form-error {
        @extend %_error-on-field;
        margin-top: -6px;
    }

    .enter-move-details-link {
        font-weight: $font-weight-bold;
        text-decoration: underline;
        display: inline-block;
        margin-top: $grid-gutter-width / 2;

        @media (min-width: $screen-md-min) {
            margin-top: $grid-gutter-width - 7;
        }
    }

    .form-group.has-error {
        + .form-group,
        ~ .col-md-offset-1 {
            .enter-move-details-link {
                margin-top: $grid-gutter-width * 1.5;
            }
        }
    }

    @media (min-width: $screen-sm-min) {
        .moving-from {
            padding-right: $grid-gutter-width;
            position: relative;

            .moving-country {
                right: round($grid-gutter-width * 1.5) - $_country-right-offset;
            }

            .fa-arrow-right {
                position: absolute;
                right: $grid-gutter-width/-2;
                top: 42px;
            }

            .form-error {
                right: $grid-gutter-width;
            }
        }

        .moving-to {
            padding-left: $grid-gutter-width;

            .form-error {
                left: $grid-gutter-width;
            }
        }
    }
}
