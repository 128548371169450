/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

//
//  Dots
// ---------------------------------------
$slick-dot-color:           $gray-dark;
$slick-dot-color-active:    $brand-primary;
$slick-dot-size:            .625rem;
$slick-opacity-default:     0.75;
$slick-opacity-on-hover:    1;
$slick-opacity-not-active:  0.25;

.slick-dotted.slick-slider {
    margin-bottom: 2.25rem;
}

.slick-dots {
    position: absolute;
    top: calc(100% + .5rem);
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
        position: relative;
        display: inline-block;
        margin: 0;
        padding: 0;
        cursor: pointer;

        button {
            border: 0;
            background: transparent;
            display: block;
            outline: none;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            line-height: 0;
            font-size: 0;

            &:hover, &:focus {
                outline: none;

                &:before {
                    opacity: $slick-opacity-on-hover;
                }
            }

            &:before {
                content: "";
                display: block;
                width: $slick-dot-size;
                height: $slick-dot-size;
                background-color: $slick-dot-color;
                opacity: $slick-opacity-not-active;
                border-radius: 50%;
            }
        }

        &.slick-active button:before {
            background-color: $slick-dot-color-active;
            opacity: $slick-opacity-on-hover;
        }
    }
}