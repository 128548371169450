
.ss-container {
	
    width: 100%;
    position: relative;
    text-align: left;
    float: left;
	margin-top: 30px;
	
	@media (min-width: $screen-sm-min) {
		
		margin-top: 60px;
		padding-bottom: 200px;
		margin-bottom: -120px;
		
		&:before {
			position: absolute;
			width: 19px;
			background-image: url(/wp-content/uploads/2016/07/timeline.png);
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center top;
			top: 0px;
			left: 50%;
			margin-left: -2.5%;
			content: '';
			height: 100%;
		}
	}
	
	.ss-row{
		width: 100%;
		clear: both;
		float: left;
		position: relative;
		
		padding-top: 40px;
		
		@media (min-width: $screen-sm-min) {
			padding-top: 155px;
		}
		
		.ss-left, .ss-right {
			
			position: relative;			
			width: 100%;
			
			@media (max-width: $screen-sm-max) {
				
				/* remove the left / right animation for mobile devices */
				left: 0 !important;
				right: 0 !important;
			}
					
			@media (min-width: $screen-sm-min) {
			
				float: left;
				width: 48%;

				h3 {
					margin-top: 60px;
					font-weight: 300;
					font-size: 18px;	
				}
			}
			
			@media (min-width: $screen-lg-min) {
				p.text-only {
					padding-top: 40px;	
				}
			}
		}
		
		.ss-right{

			text-align: center;
				
			@media (min-width: $screen-sm-min) {
				
				text-align: left;
				padding-left: 30px;
				
				h3 {
					margin-left: 93px;
					
					&:before {
						content: '';
						position: absolute;
						height: 1px;
						width: 78px;
						background-color: #626261;
						top: 70px;
						left: 26px;
					}
				}
			}
			
			img {
				
				@media (min-width: $screen-sm-min) {
					margin: 0 15px 0 3px;
				}
				
				@media (min-width: $screen-md-min) {
					float: left;	
				}
				
				@media (min-width: $screen-lg-min) {
					margin-right: 30px;
				}
			}
			

		}
		
		.ss-left{
			
			text-align: center;
			
			@media (min-width: $screen-sm-min) {
				
				text-align: right;
				float: left;
				padding-right: 30px;
				
				h3 {
					
					margin-right: 93px;
					
					&:after {
						content: '';
						position: absolute;
						height: 1px;
						width: 78px;
						background-color: #626261;
						top: 70px;
						right: 26px;
					}
				}
			}
			
			img {
				
				@media (min-width: $screen-sm-min) {
					margin: 0 3px 0 15px;
				}
				
				@media (min-width: $screen-md-min) {
					float: right;	
				}
				
				@media (min-width: $screen-lg-min) {
					margin-left: 30px;
				}
			}
		}
		
		img {
			border-radius: 50%;
			border: solid 1px #b9b9b9;
			box-shadow: 0px 0px 2px rgba(0,0,0,0.2);
		}
	}

	> :first-child {
		padding-top: 0;	
	}
}



