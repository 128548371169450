//@font-face {
//    font-family: 'DIN';
//    src: url('fonts/din/subset-DINPro-Regular.eot');
//    src: url('fonts/din/subset-DINPro-Regular.eot?#iefix&v=#{$fa-version}') format('embedded-opentype'),
//    url('fonts/din/subset-DINPro-Regular.woff2') format('woff2'),
//    url('fonts/din/subset-DINPro-Regular.woff') format('woff'),
//    url('fonts/din/subset-DINPro-Regular.ttf') format('truetype');
//    font-weight: 400;
//}
//
//@font-face {
//    font-family: 'DIN';
//    src: url('fonts/din/subset-DINPro-Medium.eot');
//    src: url('fonts/din/subset-DINPro-Medium.eot?#iefix&v=#{$fa-version}') format('embedded-opentype'),
//    url('fonts/din/subset-DINPro-Medium.woff2') format('woff2'),
//    url('fonts/din/subset-DINPro-Medium.woff') format('woff'),
//    url('fonts/din/subset-DINPro-Medium.ttf') format('truetype');
//    font-weight: 700;
//}

// Hind Guntur Font Family

@font-face {
    font-family: 'Hind_Gunt Regular';
    font-display: fallback;
    src: local('Hind_Gunt Regular'), local('Hind_Gunt-Regular'), url('../../fonts/HindGuntur-Regular.eot');
    src: local('Hind_Gunt Regular'), local('Hind_Gunt-Regular'), url("../../fonts/HindGuntur-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../../fonts/HindGuntur-Regular.woff") format("woff"), /* Modern Browsers */
    url("../../fonts/HindGuntur-Regular.woff2") format("woff2"), /* Modern Browsers */
    url("../../fonts/HindGuntur-Regular.svg") format("svg"), /* Legacy iOS */
    url("../../fonts/HindGuntur-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Hind_Gunt Medium';
    font-display: fallback;
    src: local('Hind_Gunt Medium'), local('Hind_Gunt-Medium'), url('../../fonts/HindGuntur-Medium.eot');
    src: local('Hind_Gunt Medium'), local('Hind_Gunt-Medium'), url("../../fonts/HindGuntur-Medium.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../../fonts/HindGuntur-Medium.woff") format("woff"), /* Modern Browsers */
    url("../../fonts/HindGuntur-Medium.woff2") format("woff2"), /* Modern Browsers */
    url("../../fonts/HindGuntur-Medium.svg") format("svg"), /* Legacy iOS */
    url("../../fonts/HindGuntur-Medium.ttf") format("truetype"); /* Safari, Android, iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Hind_Gunt Bold';
    font-display: fallback;
    src: local('Hind_Gunt Bold'), local('Hind_Gunt-Bold'), url('../../fonts/HindGuntur-Bold.eot');
    src: local('Hind_Gunt Bold'), local('Hind_Gunt-Bold'), url("../../fonts/HindGuntur-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../../fonts/HindGuntur-Bold.woff") format("woff"), /* Modern Browsers */
    url("../../fonts/HindGuntur-Bold.woff2") format("woff2"), /* Modern Browsers */
    url("../../fonts/HindGuntur-Bold.svg") format("svg"), /* Legacy iOS */
    url("../../fonts/HindGuntur-Bold.ttf") format("truetype"); /* Safari, Android, iOS */
    font-weight: normal;
    font-style: normal;
}
