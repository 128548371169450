@mixin vertical-center() {
    transform:  translateY(-50%);
    top: 50%;
}

@mixin horizontal-center() {
    transform:  translateX(-50%);
    left: 50%;
}

@mixin all-center() {
    transform:  translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

@mixin logo-size($width) {
    width: $width;
    height: getLogoHeight($width);
}

@mixin wrap-links() {
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

@mixin hat-dimensions($width) {
    $_ratio: 144.68/163.89;

    width: $width;
    height: $width / $_ratio;
}


