.step4 {

    #movingDate_datepicker + .form-error {
        margin-top: -15px;
    }

    #movingDateInput + .form-error {
        @extend %_error-on-field;
        margin-top: 0;
    }

    @media (min-width: $screen-sm-min) {
        .btn-primary {
            margin-top: 96px;
        }
    }
	
	/* .award {
        text-align: left;
		margin-top: 14px;
        position: relative;
        padding-left: map-fetch($logos, emma width) + 10px;
        margin-bottom: 0;

        @media (min-width: $screen-sm-min) {
            margin-top: 24px;
        }

        &:before {
            @include logo-sprite('emma');
            @include vertical-center();
            content: "";
            display: block;
            position: absolute;
            left: 0;
        }
	} */

    .phone-bottom-right {
        text-align: center;

        @media (min-width: $screen-sm-min) {
            text-align: right;
        }
    }

    .storage-duration-label {
        display: block;
        margin-top: 1em;

        @media (min-width: $screen-sm-min) {
            margin-top: 0;
        }
    }

    .storage-radio-buttons-holder {
        display: inline-block;
        margin: 0 auto 2em;

        @media (min-width: $screen-sm-min) {
            margin-bottom: 0;
        }

        .group-radio-checkbox {
            margin-bottom: 2rem;
        }
		
		label {
			font-weight: $font-weight-bold;
		}
    }
}

.group-radio-checkbox {
    position: relative;

    label {
        $check-radio--width: 20px;
        $check-radio--height: 20px;
        $check-radio--inner-offset: 8;

        margin: 0;
        cursor: pointer;
        position: relative;
        font-weight: $font-weight-bold;
        padding-left: $check-radio--width + 10px;

        &:before {
            @include vertical-center();
            transition: all .3s;
            display: block;
            position: absolute;
            content: " ";
            border: 1px solid $input-border;
            width: $check-radio--width;
            height: $check-radio--height;
            left: 1px;           //This is to counter rendering issues when label has ellipsis
            background-color: $input-bg;
            border-radius: $input-border-radius;
        }

        &:after {
            display: none;
            position: absolute;
            content: "";
            width: $check-radio--width - $check-radio--inner-offset;
            height: $check-radio--width - $check-radio--inner-offset;
            border-radius: $input-border-radius;
            background-color: $orange;
            left: 1px + round($check-radio--inner-offset / 2);
            top: 1px + round($check-radio--inner-offset / 2);
        }

        &:focus, &:active, &:hover, &:focus, &:active {
            &:before {
                border: 1px solid $orange;
            }
        }
    }

    &.disabled label:hover:before {
        border: 1px solid $input-border;
    }

    &.radio {
        label {
            &:before,
            &:after {
                border-radius: 50%;
            }
        }
    }

    input {
        left: 0;
        top: 0;
        visibility: hidden;
        position: absolute;
    }

    input:checked + label {
        &:after {
            display: block;
        }
    }
}