@mixin datepicker-button-variant($color, $background, $border) {
    color: $color;
    background-color: $background;
    border-color: $border;

    &:focus,
    &:active,
    &.active {
        color: $color;
        background-color: $background;//darken($background, 8%);
        border-color: darken($border, 12%);
    }

    &:hover{
        color: $color;
        background-color: darken($background, 8%);
        border-color: darken($border, 12%);
    }
}

.datepicker {
    &.datepicker-inline {
        display: inline-block;
        min-height: 295px;
    }

    &.datepicker-dropdown {
        position: absolute;
        left: 0;
        z-index: 9999;

        &:before {
            content: '';
            display: inline-block;
            border-left:   7px solid transparent;
            border-right:  7px solid transparent;
            border-bottom: 7px solid #ccc;
            border-top:    0;
            border-bottom-color: rgba(0,0,0,.2);
            position: absolute;
        }

        &:after {
            content: '';
            display: inline-block;
            border-left:   6px solid transparent;
            border-right:  6px solid transparent;
            border-bottom: 6px solid $white;
            border-top:    0;
            position: absolute;
        }

        &.datepicker-orient-left:before   { left: 107px; }
        &.datepicker-orient-left:after    { left: 108px; }
        &.datepicker-orient-right:before  { right: 107px; }
        &.datepicker-orient-right:after   { right: 108px; }
        &.datepicker-orient-top:before    { top: -7px; }
        &.datepicker-orient-top:after     { top: -6px; }

        &.datepicker-orient-bottom:before {
            bottom: -7px;
            border-bottom: 0;
            border-top:    7px solid $gray-light;
        }

        &.datepicker-orient-bottom:after {
            bottom: -6px;
            border-bottom: 0;
            border-top:    6px solid $white;
        }
    }

    > div {
        display: none;
    }

    &.days div.datepicker-days {
        display: block;
    }

    &.months div.datepicker-months {
        display: block;
    }

    &.years div.datepicker-years {
        display: block;
    }

    table {
        margin: 0;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        user-select: none;
        border-spacing: 2px;
        border-collapse: separate;

        tr {
            td, th {
                text-align: center;
                width: 30px;
                height: 30px;
                border-radius: $border-radius-base;
                border: none;
            }
        }
    }

    // Inline display inside a table presents some problems with
    // border and background colors.
    .table-striped & table tr {
        td, th {
            background-color:transparent;
        }
    }

    table tr td {
        &.day {
            color: $gray-lighter;
            border: 1px solid $gray-lighter;

            &:hover, &.focused {
                background: rgba($btn-warning-bg, .6);
                color: $white;
                cursor: pointer;
            }
        }

        &.old,
        &.new {
            color: $btn-link-disabled-color;
            border: none;
        }

        &.disabled,
        &.disabled:hover {
            background: none;
            color: $btn-link-disabled-color;
            cursor: default;
            border-color: darken($gray-light, 10%);
        }

        &.today,
        &.today:hover,
        &.today.disabled,
        &.today.disabled:hover {
            color: $brand-warning;
        }

        &.today:hover:hover { // Thank bootstrap 2.0 for this selector...
            // TODO: Bump min BS to 2.1, use $textColor in buttonBackground above
            color: #000;
        }

        &.today.active:hover {
            color: $white;
        }

        &.range,
        &.range:hover,
        &.range.disabled,
        &.range.disabled:hover {
            background:$gray-lighter;
            border-radius: 0;
        }

        &.range.today,
        &.range.today:hover,
        &.range.today.disabled,
        &.range.today.disabled:hover {
            $today-bg: $brand-info;
            @include datepicker-button-variant($black, $today-bg, darken($today-bg, 20%));
            border-radius: 0;
        }

        &.selected,
        &.selected:hover,
        &.selected.disabled,
        &.selected.disabled:hover {
            @include datepicker-button-variant($white, $gray-light, $gray);
            text-shadow: 0 -1px 0 rgba(0,0,0,.25);
        }

        &.active,
        &.active:hover,
        &.active.disabled,
        &.active.disabled:hover {
            @include datepicker-button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
            text-shadow: 0 -1px 0 rgba(0,0,0,.25);
        }

        span {
            display: block;
            width: 23%;
            height: 54px;
            line-height: 54px;
            float: left;
            margin: 1%;
            cursor: pointer;
            border-radius: $border-radius-base;

            &:hover {
                background: rgba($btn-warning-bg, .6);
            }

            &.disabled,
            &.disabled:hover {
                background: none;
                color: $btn-link-disabled-color;
                cursor: default;
            }

            &.active,
            &.focused,
            &.active:hover,
            &.focused:hover,
            &.active.disabled,
            &.active.disabled:hover {
                @include datepicker-button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
                text-shadow: 0 -1px 0 rgba(0,0,0,.25);
            }

            &.old,
            &.new {
                color: $btn-link-disabled-color;
            }
        }
    }

    th {
        &.next, &.prev {
            cursor: pointer;
        }

        &.disabled {
            color: $btn-link-disabled-color;
            pointer-events: none;
        }

        &.datepicker-switch {
            cursor: pointer;
            width: 145px;
        }
    }

    thead tr:first-child th,
    tfoot tr th {
        cursor: pointer;
        color: $brand-primary;
        &:hover{
            background: $gray-lighter;
        }
    }

    // Basic styling for calendar-week cells
    .cw {
        font-size: 10px;
        width: 12px;
        padding: 0 2px 0 5px;
        vertical-align: middle;
    }

    thead tr:first-child th.cw {
        cursor: default;
        background-color: transparent;
    }
}

.input-group {
    &.date {
        .input-group-addon i {
            cursor: pointer;
            width: 16px;
            height: 16px;
        }
    }
}

.input-daterange {
    input {
        text-align:center;
    }
    input:first-child {
        border-radius: 3px 0 0 3px;
    }
    input:last-child {
        border-radius: 0 3px 3px 0;
    }
    .input-group-addon {
        width: auto;
        min-width: 16px;
        padding: 4px 5px;
        font-weight: normal;
        line-height: $line-height-base;
        text-align: center;
        text-shadow: 0 1px 0 $white;
        vertical-align: middle;
        background-color: $input-group-addon-bg;
        border: solid $input-group-addon-border-color;
        border-width: 1px 0;
        margin-left:-5px;
        margin-right:-5px;
    }
}

@media (max-width: $screen-sm-max) {
    .date-picker-column {
        padding: 0;

        .day {
            padding: 7px;
        }
    }
}