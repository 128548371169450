// ============================================================================ //
//  General utils
// ============================================================================ //

// Removes the units from a numerical value
// @return      Number      $n stripped of its unit value
@function parse-int($n) {
    @return $n / ($n * 0 + 1);
}

@function getLogoHeight($width) {
    @return round(0.2989449003516999 * $width);
}

@function map-fetch($map, $keys) {
    $key: nth($keys, 1);
    $length: length($keys);
    $value: map-get($map, $key);
    @if ($length > 1) {
        $rest: ();
        @for $i from 2 through $length {
            $rest: append($rest, nth($keys, $i))
        }
        @return map-fetch($value, $rest)
    } @else {
        @return $value;
    }
}