.step5 {
    .form-error {
        @extend %_error-on-field;
        margin-top: 0;
        z-index: 2;
    }
	
	.award {
        text-align: center;
		text-transform: uppercase;
		margin-top: 14px;
        position: relative;
        margin-bottom: 0;
		font-size: 18px;

		.orange {
			color: $orange;	
		}

        @media (min-width: $screen-sm-min) {
            margin-top: -37px;
        }

        .award-medal {
            display: inline-block !important;
            margin: 0 3px;

            $years: '13', '14', '15', '16', '17';
            @each $year in $years {
                &.emma#{$year} {
                    @include logo-sprite('emma' + $year);
                }
            }
        }

        @media (max-width: $screen-sm-max) {
            .awards {
                margin: 0 -20px;
            }
        }
	}

    .phone-bottom-right {
        text-align: center;

        @media (min-width: $screen-sm-min) {
            text-align: right;
        }
    }
}