.news-posts {
    margin-top: $grid-gutter-width;
    display: flex;
    flex-wrap: wrap;
}

.news-post {
    margin-bottom: $grid-gutter-width;

    @media (min-width: $screen-sm-min) {
        min-height: 440px;
    }

    .post-img {
        background: $gray-lightest no-repeat center center;
        background-size: cover;
        box-sizing: content-box;
        padding-bottom: 50%;
        position: relative;

        > a {
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }

    .title {
        text-transform: none;
        margin-bottom: 0;
    }

    .moretag {
        text-transform: uppercase;
        font-weight: $font-weight-bold;
    }
}

.blog-detail-img {
    margin: 30px 0px;
    max-height: 500px;
    overflow: hidden;

    img {
        width: 100%;
    }
}
//
//  Post dates
// ---------------------------------------
.posted-on {
    display: block;
    color: $brand-primary;
    margin: $padding-small-vertical/2 0;
}

//
//  Load more ajax
// ---------------------------------------
#ajax-load-more-sp-trigger {
    @extend .btn;
    @extend .btn-primary;
    @extend .btn-sm;
    transition: padding-left .2s;
    position: relative;

    &.posts-loading {
        padding-left: $padding-small-horizontal * 2;

        &:before {
            @extend .fa;
            @extend .fa-refresh;
            @extend .fa-spin;
            position: absolute;
            left: $padding-small-horizontal;

        }
    }
}