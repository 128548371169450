//
//  Utility classes to show/hide elements when the mobile nav is visible/hidden
// ---------------------------------------
@media (max-width: $grid-float-breakpoint-max) {
    @include responsive-invisibility('.hidden-mobile-nav');
}
@media (min-width: $grid-float-breakpoint) {
    @include responsive-invisibility('.hidden-desktop-nav');
}

@media (max-width: $grid-float-breakpoint-max) {
    @include responsive-visibility('.visible-mobile-nav');
    @include responsive-invisibility('.visible-desktop-nav');
}

@media (min-width: $grid-float-breakpoint) {
    @include responsive-invisibility('.visible-mobile-nav');
    @include responsive-visibility('.visible-desktop-nav');
}

//
//  A simple case classes
// ---------------------------------------
.normalcase {
    text-transform: none;
}

//
//  A simple overlfow hidden class
// ---------------------------------------
.container-overflow-hidden {
    overflow: hidden;
}

//
//  A class to use for the dark overlay in the page header banner area
// ---------------------------------------
.dark-overlay {
    background: rgba($gray-base, .6);
}

// ============================================================================ //
//  Heading helpers
// ============================================================================ //
.no-margin-top {
    margin-top: 0;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.base-color {
    color: $text-color;
}

// ============================================================================ //
//  Animations
// ============================================================================ //

//
//  Error alert - visual shake on elements that need attention
//  Used during form validation
// ---------------------------------------
.error-alert {
    animation: error-alert 0.5s 1;
}

@keyframes error-alert {
    0%, 100%      { transform: translate3d(0, 0, 0); }
    15%, 45%, 75% { transform: translate3d(-10px, 0, 0); }
    30%, 60%, 90% { transform: translate3d(10px, 0, 0); }
}

.form-error {
    font-weight: $font-weight-bold;
    color: $brand-danger;
}

//
//  Fade in
// ---------------------------------------
.fade-in {
    animation: fadein .75s 1;
}

@keyframes fadein {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
