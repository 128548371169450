
// Counters

.counter-section {
	
	h3 {
		margin-bottom: 50px;	
	}
	
	.counter {
		text-align: center;
		
		margin-bottom: 20px;
		
		@media (min-width: $screen-sm-min) {
			margin-bottom: 0;
		}
	}
	
	.figure, .item {
		display: block;
		color: $orange;
	}
	
	.figure {
		
		font-size: 40px;
		font-weight: $font-weight-bold;
		
		@media (min-width: $screen-md-min) {
			font-size: 50px;
		}
		
		@media (min-width: $screen-lg-min) {
			font-size: 80px;
		}
	}
	
	.item {
		font-size: 24px;
		text-transform: uppercase;
	}
}