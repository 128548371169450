.step1B {
    .moving-from,
    .moving-to {
        position: relative;

        label {
            @extend %label-over-field;
        }

        input[type="text"].form-control {
            @include field-with-label-over() {
                padding-left: 66px;
            }
        }

        .moving-country {
            top: 10px;
        }
    }

    .form-group {
        @extend %stacked-quote-fields;
    }

    .form-error {
        @extend %_error-on-field;
        margin-top: -6px;
    }
}