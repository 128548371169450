$logos: (
  fidi:   (width: 66,  height: 58, x: 0,    y: 0),
  faim:   (width: 60,  height: 62, x: -76,  y: 0),
  nzoma:   (width: 63,  height: 63, x: -187, y: 0), // NZOMA
  iam:    (width: 109, height: 61, x: -260, y: 0),
  afra:   (width: 41,  height: 82, x: -379, y: 0),
  sai-q:  (width: 39,  height: 86, x: -430, y: 0),
  sai-e:  (width: 39,  height: 86, x: -479, y: 0),
  sai-h:  (width: 47,  height: 86, x: -781, y: 0),
  emma13: (width: 54,  height: 53, x: -528, y: 0),
  emma14: (width: 53,  height: 53, x: -592, y: 0),
  emma15: (width: 53,  height: 53, x: -655, y: 0),
  emma16: (width: 53,  height: 53, x: -718, y: 0),
  oma:    (width: 90,  height: 74, x: -838, y: 0),
  emma17: (width: 53,  height: 53, x: -937, y: 0)
);

$logos-sprite-size: (width: 990, height: 86);

%logo-sprite {
    display: block;
    background: url("/ResourcePackages/Allied/assets/nz-dist/img/logo-sprite.png") no-repeat;
    background-size: #{map-get($logos-sprite-size, width)}px #{map-get($logos-sprite-size, height)}px;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background: url("assets/logo-sprite@2x.png?20180509") no-repeat;
        background-size: #{map-get($logos-sprite-size, width)}px #{map-get($logos-sprite-size, height)}px;
    }
}

@mixin logo-sprite($sprite) {
    @extend %logo-sprite;
    width: #{map-fetch($logos, $sprite width)}px;
    height: #{map-fetch($logos, $sprite height)}px;

    background-position: #{map-fetch($logos, $sprite x)}px #{map-fetch($logos, $sprite y)}px;

    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
        //background-position: #{round(map-fetch($logos, $sprite x)/2)}px #{round(map-fetch($logos, $sprite y)/2)}px;
    }
}

@mixin resize-sprite($sprite, $pct) {
    $spriteWidth:     map-get($logos-sprite-size, width);
    $spriteHeight:    map-get($logos-sprite-size, height);
    $width:           map-fetch($logos, $sprite width);
    $height:          map-fetch($logos, $sprite height);

    background-size: #{round($spriteWidth * $pct)}px #{round($spriteHeight * $pct)}px;

    width: #{round($width * $pct)}px;
    height: #{round($height * $pct)}px;

    $pos: map-fetch($logos, $sprite x), map-fetch($logos, $sprite y);
    background-position: #{round(nth($pos, 1) * $pct)}px #{round(nth($pos, 2) * $pct)}px;

    @media (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
        & {
            $spriteWidth:     $spriteWidth * 2;
            $spriteHeight:    $spriteHeight * 2;
            $pos:             (map-fetch($logos, $sprite x) * 2), (map-fetch($logos, $sprite y) * 2);
            $width:           $width * 2;
            $height:          $height * 2;

            width: #{round(round($width * $pct)/2) + 1}px;
            height: #{round(round($height * $pct)/2) + 1}px;

            background-size: #{round(round($spriteWidth * $pct)/2)}px #{round(round($spriteHeight * $pct)/2)}px;
            background-position: #{round(round(nth($pos, 1) * $pct)/2)}px #{round(round(nth($pos, 2) * $pct)/2)}px;
        }
    }
}