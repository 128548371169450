// The navigation header style used in template such as for Resources sub pages
%_nav-btns {
    @include vertical-center();
    @include button-variant($text-color, $white, transparent);
    position: absolute;
    font-size: $font-size-h3;
}

.title-navigation {
    position: relative;
    margin-bottom: 30px;

    > .prev-title,
    > .next-title {
        color: $gray-light;
        font-size: $font-size-h4;
        margin-top: 8px;
    }

    > .current-title {
        position: relative;
        color: $text-color;
        font-size: $font-size-h1;
        font-weight: $font-weight-bold;
    }

    .go-prev {
        @extend %_nav-btns;
        left: 0;
    }

    .go-next {
        @extend %_nav-btns;
        right: 0;
    }
}