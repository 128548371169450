.ui-autocomplete {
    @extend .dropdown-menu;
    z-index: 2;
    max-width: 340px;

    .ui-menu-item:nth-child(1n+11) {
        display: none;
    }

    .ui-menu-item-wrapper {
        $font-adjustment: 2px; // Adjustment for new font riding high

        display: block;
        padding: (4px + $font-adjustment) 20px (4px - $font-adjustment);
        clear: both;
        font-weight: normal;
        line-height: $line-height-base;
        color: $dropdown-link-color;
        white-space: nowrap; // prevent links from randomly breaking onto new lines
        cursor: pointer;

        &:hover,
        &:focus {
            text-decoration: none;
            color: $dropdown-link-hover-color;
            background-color: $dropdown-link-hover-bg;
        }
    }
}
